import styles from "./toastNotifications.module.scss";
import { message } from "antd";
import { ReactComponent as SuccessIcon } from "bootstrap-icons/icons/check-circle.svg";
import { ReactComponent as ErrorIcon } from "bootstrap-icons/icons/x-circle.svg";
import cn from "classnames";

export const toastNotifications: typeof message = { ...message };

toastNotifications.open = (args) => {
  const getIcon = () => {
    if (args.icon !== undefined) return args.icon;
    const iconStyles = {
      position: "relative" as const,
      top: 3,
      flexShrink: 0,
    };

    switch (args.type) {
      case "success": return <SuccessIcon style={{ color: "var(--color-success)", ...iconStyles }} />;
      case "error": return <ErrorIcon style={{ color: "var(--color-error)", ...iconStyles }} />;
    }
  };

  return message.open({
    ...args,
    icon: getIcon(),
    className: cn(styles.content, args.className),
  });
};
