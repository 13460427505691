import { useParams } from "react-router-dom";
import SignupPage from "components/routes/authGroup/Signup";

export const OauthPage = () => {
  const { provider } = useParams<{provider: string}>();
  const code = new URLSearchParams(location.search).get("code");

  return (
    <SignupPage
      provider={provider}
      code={code}
    />
  );
};
