import { Redirect } from "react-router-dom";
import { getStartedPath } from "components/routes/dashboard/GetStartedPage/path";
import { reportsPath } from "components/routes/dashboard/ReportsPage/path";
import { findGetParameter } from "services/utils";
import { useCurrentUser } from "feature/session/useCurrentUser";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";
import store from "store/store";
import { useMemo } from "react";

export default () => {
  const { currentUser } = useCurrentUser();
  const isWhiteLabel = getIsWhiteLabel(store.getState());

  const defaultRedirectUrl = useMemo(() => {
    if (isWhiteLabel || currentUser?.config.show_starter_guide === false) {
      return reportsPath;
    }

    return getStartedPath;
  }, [currentUser?.config.show_starter_guide, isWhiteLabel]);

  return (
    <Redirect to={findGetParameter("redirect_url") || defaultRedirectUrl} />
  );
};
