import type { FC, HTMLAttributes } from "react";
import { useEffect, useMemo } from "react";
import { ReactComponent as PeopleIcon } from "bootstrap-icons/icons/people.svg";
import { Badge } from "@gs/uiKit/dataDisplay/Badge/Badge";
import type { Mailbox } from "@gs/core/domain/Mailbox/Mailbox";
import type { LinkedinBrowser } from "@gs/core/domain/LinkedinBrowser/LinkedinBrowser";
import { mailboxApi } from "domain/mailbox/mailboxApi";
import { linkedinBrowserApi } from "domain/linkedinBrowser/linkedinBrowserApi";
import { useMailboxStore } from "domain/mailbox/mailboxStore";
import { useLinkedinBrowserStore } from "domain/linkedinBrowser/linkedinBrowserStore";


const getAllTeamMailboxesAndLinkedinBrowsers = async (): Promise<{
  mailboxes: Mailbox[]
  linkedinBrowsers: LinkedinBrowser[]
}> => {
  const mailboxesPromise = mailboxApi.getMailboxes({
    offset: 0,
    limit: 1000,
  }).then((response) => response.data.data);
  const linkedinBrowsersPromise = linkedinBrowserApi.getLinkedinBrowsers({
    offset: 0,
    limit: 1000,
  }).then((response) => response.data.data);

  const [mailboxes, linkedinBrowsers] = await Promise.all([mailboxesPromise, linkedinBrowsersPromise]);

  return {
    mailboxes,
    linkedinBrowsers,
  };
};

export const SenderProfilesMenuItemIcon: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const mailboxes = useMailboxStore();
  const linkedinBrowsers = useLinkedinBrowserStore();

  const isAnyError = useMemo(() => {
    const isAnyMailboxError = Object.values(mailboxes).some((mailbox) => mailbox.sync_status === "error" || mailbox.send_status === "error");
    if (isAnyMailboxError) return true;
    const isAnyLinkedinBrowserProblem = Object.values(linkedinBrowsers).some((linkedinBrowser) => {
      switch (linkedinBrowser.status) {
        case "running":
        case "initializing":
        case "idle":
        case "stopped":
        case "maintenance":
        case "running_issue": // TODO: remove
        case "error_investigation": // TODO: remove and in LinkedinSettingsTopCard and in front-app/src/domain/senderProfile/component/SenderProfileLinkedinState.tsx
          return false;
        default: return true;
      }
    });
    return isAnyLinkedinBrowserProblem;
  }, [mailboxes, linkedinBrowsers]);

  useEffect(() => {
    getAllTeamMailboxesAndLinkedinBrowsers();
    const intervalId = setInterval(getAllTeamMailboxesAndLinkedinBrowsers, 3 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Badge
      dot={isAnyError}
      {...props}
    >
      <PeopleIcon />
    </Badge>
  );
};
