import { Route, Switch } from "react-router-dom";
import Error404 from "components/routes/NotFound";
import CompaniesPage from "./Companies";
import AsyncLeadsPage from "./Leads";
import Lists from "./Lists";
import ImportsPage from "./Imports";
import { paths } from "./const";
import { useDispatch } from "react-redux";
import { loadFlowSources } from "store/entities/flowSources/actions";
import { getSenderProfiles } from "store/entities/senderProfiles/actions";

import { useEffect } from "react";
import { getCustomFields } from "store/entities/customFields/actions";
import { ContentHeader } from "feature/mainLayout/component/ContentHeader/ContentHeader";

const CrmPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCustomFields({
      limit: 1000,
    }));
    dispatch(loadFlowSources());
    dispatch(getSenderProfiles({ limit: 100 }));
  }, []);
  return (
    <>
      <ContentHeader>
        <ContentHeader.Tabs.Navigation
          tabs={[
            {
              label: "Contacts",
              path: paths.contacts,
            },
            // {
            //   label: "Accounts",
            //   path: paths.companies,
            // },
            {
              label: "Lists",
              path: paths.lists,
            },
            {
              label: "Imports",
              path: paths.imports,
            },
          ]}
        />
      </ContentHeader>

      <Switch>
        <Route path={`${paths.contacts}/:leadUuid?`}>
          <AsyncLeadsPage />
        </Route>
        <Route path={`${paths.companies}/:companyUuid?`}>
          <CompaniesPage />
        </Route>
        <Route
          exact
          path={paths.lists}
        >
          <Lists />
        </Route>
        <Route
          exact
          path={paths.imports}
        >
          <ImportsPage />
        </Route>
        <Route>
          <Error404 />
        </Route>
      </Switch>
    </>
  );
};

export default CrmPage;
