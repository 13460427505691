import type { UserId } from "@gs/core/domain/User/User";
import { useSelector } from "react-redux";
import { getWhiteLabelName } from "store/whiteLabel/selectors";
import { Button } from "@gs/uiKit/general/Button";
import { sessionService } from "feature/session/SessionService";
import { Form } from "@gs/uiKit/input/Form";
import { TextWithOptions } from "./TextWithOptions";
import { userApi } from "domain/user/userApi";
import { useState } from "react";
import { getError } from "services/utils";
import { Alert } from "@gs/uiKit/feedback/Alert";

type FormData = {
  source: string
  industry: string
}

export const StepKnowYouBetter = (props: {userId: UserId}) => {
  const whiteLabelName = useSelector(getWhiteLabelName);
  const projectName = whiteLabelName || "GetSales.io";
  const [form] = Form.useForm();
  const [error, setError] = useState("");

  const submit = async (data: FormData) => {
    try {
      await userApi.updateUser({
        fields: {
          industry: data.industry,
          source: data.source,
        },
      });
    } catch (theError) {
      setError(getError(theError));
    }
  };

  return (
    <Form
      form={form}
      onFinish={submit}
      validateTrigger="submit"
    >
      <div className="gs-fs-28 gs-fw-500 gs-mb">Let’s get to know you better</div>

      {error && (
        <Alert
          showIcon
          message={error}
          type="error"
          closable
          onClose={() => setError("")}
          className="gs-mb"
        />
      )}

      <div className="gs-fs-16 gs-mb-xl">
        We’d love to learn more about you so we can tailor your experience with
        {" "}
        {projectName}
        {" "}
        and deliver the value you need.
      </div>
      <div className="gs-fs-16 gs-fw-500 gs-mb-xs">
        How did you hear about us?
      </div>
      <Form.Item<FormData>
        name="source"
        rules={[{ required: true }]}
      >
        <TextWithOptions
          valuesPreset={[
            "Friend",
            "Linkedin",
            "Twitter",
            "Google",
            "YouTube",
            "G2",
            "Facebook",
            "TikTok",
            "Instagram",
            "Email",
          ]}
        />
      </Form.Item>

      <div className="gs-fs-16 gs-fw-500 gs-mb-xs">
        Which industry describes your business best?
      </div>
      <Form.Item<FormData>
        name="industry"
        rules={[{ required: true }]}
      >
        <TextWithOptions
          valuesPreset={[
            "Agency",
            "IT Services",
            "Software (SaaS)",
            "Recruiting",
            "Consulting/Coaching",
          ]}
        />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        style={{ width: "100%" }}
        className="gs-mt-xxl gs-mb"
      >
        Continue
      </Button>

      <div>
        <span className="gs-c-secondary">
          Need to switch user?
        </span>
        {" "}
        <Button
          type="link"
          htmlType="button"
          size="small"
          onClick={() => sessionService.signOut()}
        >
          Logout
        </Button>
      </div>
    </Form>
  );
};
