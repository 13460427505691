import type { IsoDate, Mixed, MixedNumberOrDate } from "../../commonTypes";
import type { SubscriptionId, VendorSubscriptionId } from "./Subscription";
import type { UserId } from "../User/User";
import type { PlanId } from "../Plan/Plan";
import type { SubscriptionStatus } from "./SubscriptionStatus";

export type SubscriptionFilter = {
  query?: string
  id?: Mixed<SubscriptionId>
  userId?: Mixed<UserId>
  planId?: Mixed<PlanId>
  vendorSubscriptionId?: Mixed<VendorSubscriptionId>
  name?: Mixed<string>
  email?: Mixed<string>
  periodDays?: MixedNumberOrDate<number>
  status?: Mixed<SubscriptionStatus>
  nextBillAt?: MixedNumberOrDate<IsoDate>
  nextCreditTopUpAt?: MixedNumberOrDate<IsoDate>
}

export const subscriptionFilterToServerFormat = (filter?: SubscriptionFilter) => filter && ({
  q: filter.query,
  id: filter.id,
  user_id: filter.userId,
  plan_id: filter.planId,
  vendor_subscription_id: filter.vendorSubscriptionId,
  name: filter.name,
  email: filter.email,
  period_days: filter.periodDays,
  status: filter.status,
  next_bill_at: filter.nextBillAt,
  next_credit_top_up_at: filter.nextCreditTopUpAt,
});
