import type { AvatarProps } from "@gs/uiKit/dataDisplay/Avatar";
import { Avatar } from "@gs/uiKit/dataDisplay/Avatar";
import type { FC } from "react";
import type { UserId } from "@gs/core/domain/User/User";
import { useFindOrFetchUser } from "../hook/useFindOrFetchUser";
import { useMemo } from "react";

type UserAvatarProps = Omit<AvatarProps, "src"> & {
  userId: UserId
}

export const UserAvatar: FC<UserAvatarProps> = ({ userId, ...forwardProps }) => {
  const [user, isLoading, error] = useFindOrFetchUser(userId);

  const initials = useMemo(() => {
    if (!user) return "";
    return [user.first_name, user.last_name]
      .filter(Boolean)
      .map((str) => String.fromCodePoint(str.codePointAt(0)!))
      .join("");
  }, [user?.first_name, user?.last_name]);

  if (!user) {
    return null;
  }

  return (
    <Avatar
      shape="square"
      {...forwardProps}
      src={user?.image_url}
    >
      { initials }
    </Avatar>
  );
};
