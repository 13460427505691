import { memo } from "react";
import { NavLink } from "react-router-dom";
import { path as teamsPath } from "../TeamsManagement";
import styles from "./Navigation.module.scss";
import { path as userPath } from "../User";
import { path as subscriptionsPath } from "../Subscriptions";
import { useSelector } from "react-redux";
import { getIsWhiteLabel } from "store/whiteLabel/selectors";

const Navigation = () => {
  const isWhiteLabel = useSelector(getIsWhiteLabel);
  return (
    <div className={styles.container}>
      <div className="gs-p gs-fw-600">
        Personal settings
      </div>
      <div>
        <NavLink
          to={userPath}
          activeClassName="--active"
          className={styles.link}
        >
          Profile Details
        </NavLink>
        <NavLink
          to={teamsPath}
          activeClassName="--active"
          className={styles.link}
        >
          Teams Management
        </NavLink>
        {!isWhiteLabel && (
          <NavLink
            to={subscriptionsPath}
            activeClassName="--active"
            className={styles.link}
          >
            Billing & Subscriptions
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default memo(Navigation);
