import React, { forwardRef } from "react";
import type { FormInstance, FormProps } from "antd";
import { Form as AntdForm } from "antd";

const FormFC = forwardRef<FormInstance, FormProps>( (props, ref) => {
  return (
    // @ts-ignore
    <AntdForm
      {...props as any}
      layout={props.layout || "vertical"}
      ref={ref}
    />
  );
});

// forwardRef returns object. not FC
export const Form: typeof AntdForm = Object.assign({}, AntdForm, FormFC);
