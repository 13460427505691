import withMemo from "@eabgaryan/with-memo";
import stableStringify from "fast-json-stable-stringify";
import { getBaseAxios } from "services/axios";
import { mailboxStore } from "./mailboxStore";
import { createMailboxApi } from "@gs/core/domain/Mailbox/createMailboxApi";
import type { Mailbox, MailboxId } from "@gs/core/domain/Mailbox/Mailbox";

export const mailboxApi = (() => {
  const axiosInstance = getBaseAxios("emails");

  const coreApi = createMailboxApi(axiosInstance);

  const getMailbox: typeof coreApi.getMailbox = async (...args) => {
    const result = await coreApi.getMailbox(...args);

    mailboxStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const getMailboxMemoized = withMemo(
    getMailbox,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  const getMailboxes: typeof coreApi.getMailboxes = async (...args) => {
    const result = await coreApi.getMailboxes(...args);

    mailboxStore.setState(
      result.data.data.reduce<Record<MailboxId, Mailbox>>((acc, mailbox) => {
        acc[mailbox.uuid] = mailbox;
        return acc;
      }, {}),
    );

    return result;
  };

  const getMailboxesMemoized = withMemo(
    getMailboxes,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  const setupCustomTrackingDomain: typeof coreApi.setupCustomTrackingDomain = async (...args) => {
    const result = await coreApi.setupCustomTrackingDomain(...args);

    getMailboxesMemoized.invalidateCache();
    getMailboxMemoized.invalidateCacheByArgs({ id: args[0].mailboxId });
    mailboxStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const removeCustomTrackingDomain: typeof coreApi.removeCustomTrackingDomain = async (...args) => {
    const result = await coreApi.removeCustomTrackingDomain(...args);

    getMailboxesMemoized.invalidateCache();
    getMailboxMemoized.invalidateCacheByArgs({ id: args[0].mailboxId });

    mailboxStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const updateMailbox: typeof coreApi.updateMailbox = async (...args) => {
    const result = await coreApi.updateMailbox(...args);

    getMailboxesMemoized.invalidateCache();
    getMailboxMemoized.invalidateCacheByArgs({ id: args[0].mailboxId });

    mailboxStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const createMailbox: typeof coreApi.createMailbox = async (...args) => {
    const result = await coreApi.createMailbox(...args);

    getMailboxesMemoized.invalidateCache();

    mailboxStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const deleteMailbox: typeof coreApi.deleteMailbox = async (...args) => {
    const result = await coreApi.deleteMailbox(...args);

    getMailboxesMemoized.invalidateCache();
    getMailboxMemoized.invalidateCacheByArgs({ id: args[0].id });

    return result;
  };

  return {
    createMailbox,
    updateMailbox,
    deleteMailbox,
    getMailbox,
    getMailboxMemoized,
    getMailboxes,
    getMailboxesMemoized,
    setupCustomTrackingDomain,
    removeCustomTrackingDomain,
    findEmailProvider: coreApi.findEmailProvider,
  };
})();
