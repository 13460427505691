import { Flex } from "@gs/uiKit/layout/Flex";
import { ReactComponent as Badge1 } from "./badges/badge1.svg";
import { ReactComponent as Badge2 } from "./badges/badge2.svg";
import { ReactComponent as Badge3 } from "./badges/badge3.svg";
import { ReactComponent as Badge4 } from "./badges/badge4.svg";
import { ReactComponent as Badge5 } from "./badges/badge5.svg";
import { ReactComponent as Badge6 } from "./badges/badge6.svg";
import { ReactComponent as Badge7 } from "./badges/badge7.svg";
import { ReactComponent as Badge8 } from "./badges/badge8.svg";

export const BgBadges = () => (
  <Flex
    gap="small"
    justify="space-between"
  >
    <Badge1 />
    <Badge2 />
    <Badge3 />
    <Badge4 />
    <Badge5 />
    <Badge6 />
    <Badge7 />
    <Badge8 />
  </Flex>
);
