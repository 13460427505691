import { useCurrentUser } from "feature/session/useCurrentUser";
import { StepKnowYouBetter } from "./StepKnowYouBetter";
import { StepEmail } from "./StepEmail";
import { useMemo } from "react";
import { NeverError } from "@gs/core/errors/NeverError";
import browserHistory from "services/browserHistory";
import authPath from "components/routes/authGroup/Signup/path";



export const UserConfirmation = () => {
  const { currentUser, currentUserStatus } = useCurrentUser();

  if (currentUserStatus === "unauthorized") {
    browserHistory.push(authPath.login);
  }
  const step = useMemo<"email" | "knowYouBetter">(() => {
    if (!currentUser) return "email";

    if (!currentUser.industry || !currentUser.source) return "knowYouBetter";

    return "email";
  }, [currentUser]);

  if (!currentUser) return null;

  switch (step) {
    case "knowYouBetter": return <StepKnowYouBetter userId={currentUser.id} />;
    case "email": return <StepEmail user={currentUser} />;
    default: throw new NeverError(step);
  }
};
