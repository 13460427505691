export const senderProfilesPath = "/sender-profiles";
export const senderProfileDetailsPath = `${senderProfilesPath}/:senderProfileId`;

export const detailPaths = {
  SPOverview: `${senderProfileDetailsPath}/details`,
  SPLinkedin: `${senderProfileDetailsPath}/linkedin`,
  SPEmail: `${senderProfileDetailsPath}/email`,
  SPAutoTasks: `${senderProfileDetailsPath}/auto-tasks`,
};
export const paths = {
  SPRoot: senderProfilesPath,
  SPDetails: senderProfileDetailsPath,
  ...detailPaths,
};

export default senderProfilesPath;
