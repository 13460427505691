import { ReactComponent as ChatIcon } from "bootstrap-icons/icons/chat.svg";
import type { FC, HTMLAttributes } from "react";
import { useEffect, useState } from "react";
import { Badge } from "@gs/uiKit/dataDisplay/Badge/Badge";
import { leadApi } from "domain/lead/leadApi";
import { dataBus } from "services/dataBus";

export const MessagesMenuItemIcon: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  const [isHaveUnreadMessages, setIsHaveUnreadMessages] = useState(false);

  useEffect(() => {
    const getMetrics = async () => {
      const response = await leadApi.getLeadMetrics({
        metrics: ["unread_count_linkedin", "unread_count_email"],
      });
      const { unread_count_linkedin, unread_count_email } = response.data.total;
      if (unread_count_linkedin > 0 || unread_count_email > 0) {
        setIsHaveUnreadMessages(true);
      } else {
        setIsHaveUnreadMessages(false);
      }
    };

    getMetrics();

    const unsubscribers = [
      dataBus.subscribe("messages_read", getMetrics),
      dataBus.subscribe("messages_unread", getMetrics),
    ];

    return () => unsubscribers.forEach((cb) => cb());
  }, []);

  return (
    <Badge
      dot={isHaveUnreadMessages}
      {...props}
    >
      <ChatIcon />
    </Badge>
  );
};
