import { getBaseAxios } from "services/axios";
import { createSenderProfileApi } from "@gs/core/domain/SenderProfile/createSenderProfileApi";
import { senderProfileStore } from "./senderProfileStore";
import type { SenderProfile, SenderProfileId } from "@gs/core/domain/SenderProfile/SenderProfile";
import { dataBus } from "services/dataBus";

export const senderProfileApi = (() => {
  const flowAxios = getBaseAxios("flowV2");

  const coreSenderProfileApi = createSenderProfileApi(flowAxios);

  const getSenderProfiles: typeof coreSenderProfileApi.getSenderProfiles = async (...args) => {
    const result = await coreSenderProfileApi.getSenderProfiles(...args);

    senderProfileStore.setState(
      result.data.data.reduce<Record<SenderProfileId, SenderProfile>>((acc, senderProfile) => {
        acc[senderProfile.uuid] = senderProfile;
        return acc;
      }, {}),
    );

    return result;
  };

  const getSenderProfile: typeof coreSenderProfileApi.getSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.getSenderProfile(...args);

    senderProfileStore.setState({
      [result.data.uuid]: result.data,
    });

    return result;
  };

  const updateSenderProfile: typeof coreSenderProfileApi.updateSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.updateSenderProfile(...args);

    senderProfileStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("senderProfile:updated", { senderProfileIds: [result.data.uuid] });

    return result;
  };

  const createSenderProfile: typeof coreSenderProfileApi.createSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.createSenderProfile(...args);

    senderProfileStore.setState({
      [result.data.uuid]: result.data,
    });

    dataBus.emit("senderProfile:created", { senderProfileIds: [result.data.uuid] });

    return result;
  };

  const deleteSenderProfile: typeof coreSenderProfileApi.deleteSenderProfile = async (...args) => {
    const result = await coreSenderProfileApi.deleteSenderProfile(...args);

    dataBus.emit("senderProfile:created", { senderProfileIds: [args[0].id] });

    senderProfileStore.setState((state) => {
      const copyState = { ...state };

      delete copyState[args[0].id];

      return copyState;
    }, true);

    return result;
  };

  return {
    createSenderProfile,
    updateSenderProfile,
    getSenderProfile,
    getSenderProfiles,
    deleteSenderProfile,
    getSenderProfilesMetrics: coreSenderProfileApi.getSenderProfilesMetrics,
  };
})();
