import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export const AsyncGetStartedPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'GetStartedPage' */ "./GetStartedPage")
      .then((r) => r.GetStartedPage)
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return Error404;
      });
  },
  // @ts-ignore
  loading: Loading,
});


