// import { MenuUnfoldOutlined } from "@ant-design/icons";
import { ReactComponent as SidebarOpenIcon } from "assets/svg/sidebar-open.svg";
import type { ReactNode } from "react";
import { Flex } from "@gs/uiKit/layout/Flex";
import { UserMassActions } from "components/common/UserMassActions/UserMassActions";
import { Button } from "@gs/uiKit/general/Button";
import { themeService } from "feature/theme/ThemeService";
import { ContentHeaderTitle } from "feature/mainLayout/component/ContentHeader/ContentHeaderTitle";
import { ContentHeaderTabs } from "feature/mainLayout/component/ContentHeader/ContentHeaderTabs";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";

export const ContentHeader = (props: { children?: ReactNode }) => {
  const theme = themeService.useStore((s) => s.theme);
  const isSideMenuClosed = mainLayoutService.useStore((s) => !s.isSideMenuOpen);

  return (
    <Flex
      align="center"
      gap="large"
      style={{
        backgroundColor: theme === "light" ? "var(--layout-light-sider-bg)" : "var(--layout-sider-bg)",
        padding: "var(--padding-xs) var(--padding-sm)",
        borderBottom: "1px var(--line-type) var(--color-split)",
      }}
    >
      {/*{isSideMenuClosed && (*/}
      {/*  <Button*/}
      {/*    onClick={mainLayoutService.openSideMenu}*/}
      {/*    icon={<SidebarOpenIcon style={{ width: 16, position: "relative", top: -1 }} />}*/}
      {/*    type="text"*/}
      {/*  />*/}
      {/*)}*/}
      <div style={{ flex: "1 1 0" }}>
        {props.children}
      </div>
      <UserMassActions />
    </Flex>
  );
};

ContentHeader.Tabs = ContentHeaderTabs;
ContentHeader.Title = ContentHeaderTitle;
