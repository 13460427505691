// import vantageSquareLogo from "../../../assets/whiteLabels/vantage/squareLogo.png";
// import { ReactComponent as GrinfiSquareLogo } from "../../../assets/whiteLabels/grinfi/squareLogo.svg";
// import { whiteLabels } from "services/whiteLabels";
import Icon from "../elements/Icon";
import { useSelector } from "react-redux";
import { squareWhiteLabelLogo } from "store/whiteLabel/selectors";

export const SquareLogo = (props: { className?: string; style?: any}) => {
  const whiteLabelLogoUrl = useSelector(squareWhiteLabelLogo);
  if (whiteLabelLogoUrl) {
    return <img
      src={whiteLabelLogoUrl}
      {...props}
    />;
  }
  // if (window.location.host === whiteLabels.ADVANTAGE.domain) {
  //   return <img src={vantageSquareLogo} {...props} />;
  // }
  // if (window.location.host === whiteLabels.GRINFI.domain) {
  //   return <GrinfiSquareLogo {...props} />;
  // }

  return <Icon
    icon="logo_circle"
    {...props}
         />;
};
