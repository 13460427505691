// @ts-ignore
import styles from "./Alert.module.scss";
import type { AlertProps as AntdAlertProps } from "antd";
import { Alert as AntdAlert } from "antd";
import React, { useMemo } from "react";
// @ts-ignore
import { ReactComponent as InfoCircleIcon } from "bootstrap-icons/icons/info-circle.svg";
import cn from "classnames";

export const Alert = (props: AntdAlertProps) => {
  const icon = useMemo(() => {
    if (!["warning", "info"].includes(props.type!)) return props.icon;

    return props.icon ?? (
      <InfoCircleIcon
        style={{
          top: 2,
        }}
      />
    );
  }, []);

  return (
    <AntdAlert
      {...props}
      icon={icon}
      className={cn(props.className, styles.alert)}
    />
  );
};
