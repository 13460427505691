import Loadable from "react-loadable";
import Loading from "components/common/Loading";

import Error404 from "components/routes/NotFound";

export { default as path } from "components/routes/SenderProfileGroup/paths";

const SenderProfilesPage = Loadable({
  loader: () => {

    return import(/* webpackChunkName:'SenderProfilesPage' */ "./SenderProfilesPage")
      .catch( (e) => console.log("Page loading err", e) || Error404 );
  },
  loading: Loading,
});

export default SenderProfilesPage;
