import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse, IsoDate, Mixed, MixedNumberOrDate, OrderType } from "../../commonTypes";
import type { Mailbox, MailboxId } from "./Mailbox";
import type { SenderProfileId } from "../SenderProfile/SenderProfile";
import type { TeamId } from "../Team/Team";
import type { UserId } from "../User/User";

export const createMailboxApi = (axios: AxiosInstance) => {
  const getMailboxes = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<MailboxId>
        teamId?: Mixed<TeamId>
        userId?: Mixed<UserId>
        senderProfileId?: Mixed<SenderProfileId>
        provider?: Mixed<Mailbox["provider"]>
        senderName?: Mixed<Mailbox["sender_name"]>
        email?: Mixed<Mailbox["email"]>
        sendStatus?: Mixed<Mailbox["send_status"]>
        syncStatus?: Mixed<Mailbox["sync_status"]>
        requestId?: Mixed<Mailbox["request_id"]>
        customTrackingDomain?: Mixed<Mailbox["custom_tracking_domain"]>
        syncHash?: unknown
        sendErrorsCount?: MixedNumberOrDate<number>
        syncErrorsCount?: MixedNumberOrDate<number>
        lastSyncAt?: MixedNumberOrDate<IsoDate>
        lastDnsCheckAt?: MixedNumberOrDate<IsoDate>
        createdAt?: MixedNumberOrDate<IsoDate>
        updatedAt?: MixedNumberOrDate<IsoDate>
        withDeleted?: boolean

      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<Mailbox>>(
      "/api/mailboxes",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: {
            q: params.filter?.query,
            uuid: params.filter?.id,
            sender_profile_uuid: params.filter?.senderProfileId,
            team_id: params.filter?.teamId,
            user_id: params.filter?.userId,
            updated_at: params.filter?.updatedAt,
            provider: params.filter?.provider,
            sender_name: params.filter?.senderName,
            email: params.filter?.email,
            send_status: params.filter?.sendStatus,
            sync_status: params.filter?.syncStatus,
            request_id: params.filter?.requestId,
            custom_tracking_domain: params.filter?.customTrackingDomain,
            sync_hash: params.filter?.syncHash,
            send_errors_count: params.filter?.sendErrorsCount,
            sync_errors_count: params.filter?.syncErrorsCount,
            last_sync_at: params.filter?.lastSyncAt,
            last_dns_check_at: params.filter?.lastDnsCheckAt,
            created_at: params.filter?.createdAt,
            deleted: params.filter?.withDeleted,
          },
        },
      },
    );
  };

  const getMailbox = (
    params: {
      id: MailboxId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<Mailbox>(
      `/api/mailboxes/${params.id}`,
      config,
    );
  };

  const setupCustomTrackingDomain = (
    params: {
      mailboxId: MailboxId
      customTrackingDomain: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Mailbox>(
      `/api/mailboxes/${params.mailboxId}/setup-custom-tracking-domain`,
      { custom_tracking_domain: params.customTrackingDomain },
      config,
    );
  };

  const removeCustomTrackingDomain = (
    params: {
      mailboxId: MailboxId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Mailbox>(
      `/api/mailboxes/${params.mailboxId}/remove-custom-tracking-domain`,
      null,
      config,
    );
  };

  const createMailbox = (
    params: {
      fields: Pick<Mailbox, "connection_settings" | "sender_profile_uuid" | "email" | "sender_name" | "bcc" | "provider">
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Mailbox>(
      "/api/mailboxes",
      params.fields,
      config,
    );
  };

  const updateMailbox = (
    params: {
      mailboxId: MailboxId
      newFields: Partial<Mailbox>
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Mailbox>(
      `/api/mailboxes/${params.mailboxId}`,
      params.newFields,
      config,
    );
  };

  const deleteMailbox = (params: { id: MailboxId }, config?: AxiosRequestConfig ) => {
    return axios.delete<void>(`/api/mailboxes/${params.id}`, config );
  };

  const findEmailProvider = (
    params: {
      email: string
    }, config?: AxiosRequestConfig,
  ) => {
    type T = {
      host: string
      port: number
      secure: true
    }
    return axios.post<{ imap: T; smtp: T }>(
      "/api/mailboxes/find-email-provider",
      { email: params.email },
      config,
    );
  };

  return {
    updateMailbox,
    getMailbox,
    getMailboxes,
    setupCustomTrackingDomain,
    removeCustomTrackingDomain,
    deleteMailbox,
    createMailbox,
    findEmailProvider,
  };
};
