import Loadable from "react-loadable";
import Loading from "components/common/Loading";
import Error404 from "components/routes/NotFound";

export const AsyncReportsPage = Loadable({
  loader: () => {
    return import(/* webpackChunkName:'ReportsPage' */ "./ReportsPage")
      .then((r) => r.ReportsPage)
      .catch( (e) => {
        console.log("Leads Page loading err", e);
        return Error404;
      });
  },
  // @ts-ignore
  loading: Loading,
});


