import type { Team, TeamId } from "@gs/core/domain/Team/Team";
import { teamApi } from "../teamApi";
import { teamStore } from "../teamStore";

export const findOrFetchTeam = async (id: TeamId): Promise<Team> => {
  const team = teamStore.getState()[id];

  if (team) return team;

  const response = await teamApi.getTeam({ id });
  return response.data;
};

