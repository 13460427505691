import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse, Mixed, OrderType } from "../../commonTypes";
import type { Flow, FlowId } from "./Flow";
import type { FlowWorkspaceId } from "../FlowWorkspace/FlowWorkspace";
import type { FlowVersionId } from "../FlowVersion/FlowVersion";
import type { UserId } from "../User/User";
import type { LeadId } from "../Lead/Lead";
import type { FlowNode } from "../FlowNode/FlowNode";

type FlowPayload = Pick<Flow, "schedule" | "flow_workspace_uuid" | "is_public">

export const createFlowApi = (axios: AxiosInstance) => {
  const getFlows = (
    params: {
      filter?: {
        query?: string
        id?: Mixed<FlowId>
        flowWorkspaceId?: Mixed<FlowWorkspaceId>
        flowVersionId?: Mixed<FlowVersionId>
        status?: Mixed<Flow["status"]>
        isPublic?: Mixed<Flow["is_public"]>
        userId?: Mixed<UserId>
        withDeleted?: Mixed<boolean>
      }
      limit: number
      offset: number
      orderField?: string
      orderType?: OrderType
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<ApiListResponse<Flow>>(
      "/api/flows/list",
      {
        order_field: params.orderField,
        order_type: params.orderType,
        limit: params.limit,
        offset: params.offset,
        filter: {
          q: params.filter?.query,
          uuid: params.filter?.id,
          flow_workspace_uuid: params.filter?.flowWorkspaceId,
          flow_version_uuid: params.filter?.flowVersionId,
          status: params.filter?.status,
          is_public: params.filter?.isPublic,
          user_id: params.filter?.userId,
          deleted: params.filter?.withDeleted,
        },
      },
      config,
    );
  };

  const getFlow = (params: {id: FlowId}) => {
    return axios.get<Flow>(`/api/flows/${params.id}`);
  };

  const createFlow = (params: {fields: FlowPayload }, config?: AxiosRequestConfig) => {
    return axios.post<Flow>("/api/flows/", params.fields, config);
  };

  const updateFlow = (params: { id: FlowId; newFields: FlowPayload }, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}`, params.newFields, config);
  };

  const deleteFlow = (params: { id: FlowId }, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/flows/${params.id}`, config);
  };

  const getPublicFlow = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.get<Flow>(`/api/flows/${params.id}/share`, config);
  };

  const copyFlow = (params: { name: Flow["name"]; nodes: FlowNode[] }, config?: AxiosRequestConfig) => {
    return axios.put<Flow>("/api/flows/duplicate", params, config);
  };

  const cancelLeadFromFlows = (params: {flowIds: FlowId[]; leadId: LeadId}, config?: AxiosRequestConfig) => {
    return axios.put<void>(`/api/flows/leads/${params.leadId}/cancel`, { flow_uuids: params.flowIds }, config);
  };

  const createFlowPublicLink = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}/share-link`, null, config);
  };

  const archiveFlow = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}/archive`, null, config);
  };

  const unarchiveFlow = (params: {id: FlowId}, config?: AxiosRequestConfig) => {
    return axios.put<Flow>(`/api/flows/${params.id}/unarchive`, null, config);
  };

  return {
    getFlows,
    getFlow,
    createFlow,
    updateFlow,
    deleteFlow,
    getPublicFlow,
    copyFlow,
    cancelLeadFromFlows,
    createFlowPublicLink,
    archiveFlow,
    unarchiveFlow,
  };
};
