import { getBaseAxios } from "services/axios";
import { subscriptionStore } from "./subscriptionStore";
import { createSubscriptionApi } from "@gs/core/domain/Subscription/createSubscriptionApi";
import type { Subscription, SubscriptionId } from "@gs/core/domain/Subscription/Subscription";
import { dataBus } from "services/dataBus";

export const subscriptionApi = (() => {
  const leadsAxios = getBaseAxios("idV2");

  const coreApi = createSubscriptionApi(leadsAxios);

  const getSubscriptions: typeof coreApi.getSubscriptions = async (...args) => {
    const result = await coreApi.getSubscriptions(...args);

    subscriptionStore.setState(
      result.data.data.reduce<Record<SubscriptionId, Subscription>>((acc, subscription) => {
        acc[subscription.id] = subscription;
        return acc;
      }, {}),
    );

    return result;
  };

  const getSubscription: typeof coreApi.getSubscription = async (...args) => {
    const result = await coreApi.getSubscription(...args);

    subscriptionStore.setState({
      [result.data.id]: result.data,
    });

    return result;
  };

  const cancelSubscription: typeof coreApi.cancelSubscription = async (...args) => {
    const result = await coreApi.cancelSubscription(...args);

    subscriptionStore.setState({
      [result.data.id]: result.data,
    });

    dataBus.emit("subscription:updated", { subscriptionIds: [args[0].id] });

    return result;
  };

  const updateSubscription: typeof coreApi.updateSubscription = async (...args) => {
    const result = await coreApi.updateSubscription(...args);

    subscriptionStore.setState({
      [result.data.id]: result.data,
    });

    dataBus.emit("subscription:updated", { subscriptionIds: [args[0].id] });

    return result;
  };

  return {
    getSubscriptions,
    getSubscription,
    cancelSubscription,
    updateSubscription,
  };
})();
