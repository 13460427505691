import type { AxiosInstance, AxiosRequestConfig } from "axios";
import type { ApiListResponse } from "../../commonTypes";
import type { TeamId, Team } from "./Team";
import type { TeamFilter } from "./TeamFilter";
import { teamFilterToServerFormat } from "./TeamFilter";
import type { UserId } from "../User/User";
import type { SubscriptionId } from "../Subscription/Subscription";

export const createTeamApi = (axios: AxiosInstance) => {
  const getTeams = (
    params: {
      filter?: TeamFilter
      limit: number
      offset: number
      orderField?: string
      orderType?: string
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.get<ApiListResponse<Team>>(
      "/api/teams",
      {
        ...config,
        params: {
          order_field: params.orderField,
          order_type: params.orderType,
          limit: params.limit,
          offset: params.offset,
          filter: teamFilterToServerFormat(params.filter),
        },
      },
    );
  };

  const getTeam = async (params: {id: TeamId}, config?: AxiosRequestConfig) => {
    return axios.get<Team>(`/api/teams/${params.id}`, config);
  };

  const createTeam = async (
    params: {
      name: string
      color: string
      inviteEmails: string[]
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Team>(
      "/api/teams",
      {
        name: params.name,
        color: params.color,
        invite_emails: params.inviteEmails,
      },
      config,
    );
  };

  const updateTeam = async (params: {id: TeamId; newFields: Partial<Pick<Team, "name" | "color">>}, config?: AxiosRequestConfig) => {
    return axios.put<Team>(`/api/teams/${params.id}`, params.newFields, config);
  };

  const deleteTeam = async (params: {id: TeamId}, config?: AxiosRequestConfig) => {
    return axios.delete<void>(`/api/teams/${params.id}`, config);
  };

  const changeTeamOwner = async (
    params: {
      newOwnerId: UserId
      teamId: TeamId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.put<Team>(
      `/api/teams/${params.teamId}/change-owner`,
      {
        new_owner_id: String(params.newOwnerId),
      },
      config,
    );
  };

  const setLicense = async (
    params: {
      subscriptionId: SubscriptionId
      teamId: TeamId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.post<Team>(
      `/api/teams/${params.teamId}/set-free-license`,
      {
        subscription_id: String(params.subscriptionId),
      },
      config,
    );
  };

  const removeLicense = async (
    params: {
      teamId: TeamId
    },
    config?: AxiosRequestConfig,
  ) => {
    return axios.delete<Team>(
      `/api/teams/${params.teamId}/remove-license`,
      config,
    );
  };

  return {
    getTeams,
    getTeam,
    createTeam,
    updateTeam,
    deleteTeam,
    changeTeamOwner,
    setLicense,
    removeLicense,
  };
};
