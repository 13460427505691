// @ts-ignore
import styles from "./Button.module.scss";
import React, { useRef, useState } from "react";
import type { ButtonProps as AntdButtonProps } from "antd";
import { Button as AntdButton } from "antd";
import type { TimeoutId } from "@gs/core/commonTypes";
import cn from "classnames";

export type ButtonProps = AntdButtonProps

const LOADING_ICON_DELAY = 150; // could be a prop
export const Button = ({ onClick, loading, className, ...restProps }: ButtonProps) => {
  const timeoutRef = useRef<TimeoutId>();
  const [isOnClickActionActive, setIsOnClickActionActive] = useState(false);
  const wrappedOnClick = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!onClick) return;
    timeoutRef.current = setTimeout(() => setIsOnClickActionActive(true), LOADING_ICON_DELAY);
    try {
      await onClick(event);
    } finally {
      clearTimeout(timeoutRef.current);
      setIsOnClickActionActive(false);
    }
  };

  return (
    <AntdButton
      loading={loading || isOnClickActionActive}
      onClick={wrappedOnClick}
      className={cn(styles.Button, className)}
      {...restProps}
    />
  );
};
