import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// const originPush = history.push;
//
// history.push = function (...args) {
//   console.trace([args]);
//   return originPush.call(this, ...args);
// };

export default history;

