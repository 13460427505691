import type { Team } from "@gs/core/domain/Team/Team";
import { useMemo } from "react";

// chat-gpt recommends
// Функция для преобразования HEX в RGB
// function hexToRgb(hex) {
//   let bigint = parseInt(hex.slice(1), 16);
//   let r = (bigint >> 16) & 255;
//   let g = (bigint >> 8) & 255;
//   let b = bigint & 255;
//
//   return { r, g, b };
// }
//
// // Функция для преобразования RGB обратно в HEX
// function rgbToHex(r, g, b) {
//   return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
// }
//
// // Функция для инверсии цвета
// function invertColor(hex) {
//   let { r, g, b } = hexToRgb(hex);
//   // Инверсия
//   let invertedR = 255 - r;
//   let invertedG = 255 - g;
//   let invertedB = 255 - b;
//
//   return rgbToHex(invertedR, invertedG, invertedB);
// }
//
// // Функция для осветления или затемнения цвета
// function adjustBrightness(hex, percent) {
//   let { r, g, b } = hexToRgb(hex);
//   // Изменение яркости
//   r = Math.min(255, Math.max(0, r + (r * percent / 100)));
//   g = Math.min(255, Math.max(0, g + (g * percent / 100)));
//   b = Math.min(255, Math.max(0, b + (b * percent / 100)));
//
//   return rgbToHex(Math.round(r), Math.round(g), Math.round(b));
// }
//
// // Пример использования
// let color = "#3498db"; // Исходный цвет
//
// let invertedColor = invertColor(color); // Инвертированный цвет
// let brightenedColor = adjustBrightness(color, 40); // Осветлённый на 40%
//
// console.log("Оригинальный цвет:", color);
// console.log("Инвертированный цвет:", invertedColor);
// console.log("Осветлённый цвет:", brightenedColor);

function adjustBrightness(hex: string, percent: number): string {
  let num = parseInt(hex.slice(1), 16);

  let r = (num >> 16) + percent;
  let g = ((num >> 8) & 0x00FF) + percent;
  let b = (num & 0x0000FF) + percent;

  r = Math.min(Math.max(0, r), 255);
  g = Math.min(Math.max(0, g), 255);
  b = Math.min(Math.max(0, b), 255);

  return "#" + (r << 16 | g << 8 | b).toString(16).padStart(6, "0");
}

export function getShadeColors(hex: string): {darkColor: string; lightColor: string} {
  if (!/^#[0-9A-Fa-f]{6}$/.test(hex)) {
    throw new Error("Invalid HEX color");
  }

  let lightColor = adjustBrightness(hex, 180);
  let darkColor = adjustBrightness(hex, -80);

  return { lightColor, darkColor };
}

export const useTeamColors = (hex: Team["color"]): {darkColor: string; lightColor: string} => {
  return useMemo(() => {
    if (!hex) return {
      darkColor: "var(--color-primary-text)",
      lightColor: "var(--color-primary-border)",
    };
    return getShadeColors(hex);
  }, [hex]);
};
