import { Flex } from "@gs/uiKit/layout/Flex";
import type { ReactNode } from "react";
import { useState } from "react";
import { Input } from "@gs/uiKit/input/Input";

type TextWithOptionsProps = {
  value?: string
  onChange?: (newValue: string) => void
  valuesPreset: string[]
}

const Btn = (props: {children: ReactNode; onClick: () => void; isActive: boolean}) => (
  <button
    onClick={props.onClick}
    type="button"
    className="gs-fs-12 gs-ph-xs gs-pv-xxs gs-border"
    style={{
      borderRadius: 4,
      backgroundColor: "var(--color-bg-base)",
      cursor: "pointer",
      color: props.isActive ? "#222CB3" : undefined,
      borderColor: props.isActive ? "#9CAFFF" : undefined,
    }}
  >
    {props.children}
  </button>
);

export const TextWithOptions = (props: TextWithOptionsProps) => {
  const { valuesPreset } = props;
  const [localValue, setLocalValue] = useState("");
  const value = props.value || localValue;
  const onChange = (newValue: string) => {
    setLocalValue(newValue);
    props.onChange?.(newValue);
  };
  const [isOtherSelected, setIsOtherSelected] = useState(() => !!value && !valuesPreset.includes(value));

  return (
    <Flex
      gap="small"
      wrap="wrap"
    >
      {valuesPreset.map((str, index) => (
        <Btn
          isActive={!isOtherSelected && value === str}
          onClick={() => {
            onChange(str);
            setIsOtherSelected(false);
          }}
          key={index}
        >
          {str}
        </Btn>
      ))}
      <Btn
        key="other"
        onClick={() => {
          if (isOtherSelected) return;
          setIsOtherSelected(true);
          onChange("");
        }}
        isActive={isOtherSelected}
      >
        Other
      </Btn>
      {isOtherSelected && (
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Other"
          style={{ width: 240 }}
        />
      )}
    </Flex>
  );
};
