import styles from "./SidebarHeader.module.scss";
import { SquareLogo } from "components/common/Logo/SquareLogo";
import { FullLogo } from "components/common/Logo/FullLogo";
import { mainLayoutService } from "feature/mainLayout/MainLayoutService";
import { Flex } from "@gs/uiKit/layout/Flex";
import { ReactComponent as SidebarCloseIcon } from "assets/svg/sidebar-close.svg";
import { ReactComponent as SidebarOpenIcon } from "assets/svg/sidebar-open.svg";
import { Button } from "@gs/uiKit/general/Button";

export const SidebarHeader = () => {
  const isSideMenuOpen = mainLayoutService.useStore((s) => s.isSideMenuOpen);

  return (
    <Flex
      gap="middle"
      justify="space-between"
      align="center"
      style={{
        width: isSideMenuOpen ? "100%" : undefined,
        padding: isSideMenuOpen ? "0 var(--padding-sm)" : undefined,
      }}
    >
      {isSideMenuOpen ? (
        <FullLogo style={{ height: 40, width: "fit-content" }} />
      ) : (
        <div className={styles.collapsedLogoWrapper}>
          <SquareLogo className={styles.collapsedLogo} />
          <div
            className={styles.collapseButton}
            onClick={mainLayoutService.openSideMenu}
          >
            <SidebarOpenIcon className={styles.collapseIcon} />
          </div>
        </div>
      )}

      {isSideMenuOpen && (
        <Button
          onClick={mainLayoutService.closeSideMenu}
          icon={<SidebarCloseIcon style={{ width: 16, position: "relative", top: -1 }} />}
          type="text"
        />
      )}
    </Flex>
  );
};
