import { getBaseAxios } from "services/axios";
import { teamStore } from "./teamStore";
import { createTeamApi } from "@gs/core/domain/Team/createTeamApi";
import type { Team, TeamId } from "@gs/core/domain/Team/Team";
import withMemo from "@eabgaryan/with-memo";
import stableStringify from "fast-json-stable-stringify";
import { dataBus } from "services/dataBus";

export const teamApi = (() => {
  const idAxios = getBaseAxios("idV2");

  const coreApi = createTeamApi(idAxios);

  const getTeams: typeof coreApi.getTeams = async (...args) => {
    const result = await coreApi.getTeams(...args);

    teamStore.setState(
      result.data.data.reduce<Record<TeamId, Team>>((acc, team) => {
        acc[team.id] = team;
        return acc;
      }, {}),
    );

    return result;
  };

  const getTeamsMemoized = withMemo(
    getTeams,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );

  dataBus.subscribe("subscription:created", () => getTeamsMemoized.invalidateCache());
  dataBus.subscribe("subscription:updated", () => getTeamsMemoized.invalidateCache());

  const getTeam: typeof coreApi.getTeam = async (...args) => {
    const response = await coreApi.getTeam(...args);
    const team = response.data;

    teamStore.setState({ [team.id]: team });

    return response;
  };

  const getTeamMemoized = withMemo(
    getTeam,
    {
      ttl: 10_000,
      getKey: stableStringify,
    },
  );


  const createTeam: typeof coreApi.createTeam = async (...args) => {
    const response = await coreApi.createTeam(...args);
    const team = response.data;

    getTeamsMemoized.invalidateCache();

    teamStore.setState({ [team.id]: team });
    dataBus.emit("team:created", { teamIds: [team.id] });

    return response;
  };


  const updateTeam: typeof coreApi.updateTeam = async (...args) => {
    const response = await coreApi.updateTeam(...args);
    const team = response.data;

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].id });

    teamStore.setState({ [team.id]: team });
    dataBus.emit("team:updated", { teamIds: [team.id] });

    return response;
  };


  const deleteTeam: typeof coreApi.deleteTeam = async (...args) => {
    const response = await coreApi.deleteTeam(...args);

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].id });

    // teamStore.setState((prev) => {
    //   const newState = { ...prev };
    //
    //   delete newState[args[0].id];
    //
    //   return newState;
    // }, true);

    dataBus.emit("team:deleted", { teamIds: [args[0].id] });

    return response;
  };


  const changeTeamOwner: typeof coreApi.changeTeamOwner = async (...args) => {
    const response = await coreApi.changeTeamOwner(...args);

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].teamId });

    teamStore.setState({ [response.data.id]: response.data });

    dataBus.emit("team:updated", { teamIds: [response.data.id] });

    return response;
  };


  const setLicense: typeof coreApi.setLicense = async (...args) => {
    const response = await coreApi.setLicense(...args);

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].teamId });

    teamStore.setState({ [response.data.id]: response.data });

    dataBus.emit("team:updated", { teamIds: [response.data.id] });

    return response;
  };


  const removeLicense: typeof coreApi.removeLicense = async (...args) => {
    const response = await coreApi.removeLicense(...args);

    getTeamsMemoized.invalidateCache();
    getTeamMemoized.invalidateCacheByArgs({ id: args[0].teamId });

    teamStore.setState({ [response.data.id]: response.data });

    dataBus.emit("team:updated", { teamIds: [response.data.id] });

    return response;
  };

  return {
    createTeam,
    getTeams,
    getTeamsMemoized,
    getTeam,
    getTeamMemoized,
    updateTeam,
    deleteTeam,
    changeTeamOwner,
    setLicense,
    removeLicense,
  };
})();
