import "moment-timezone";
import { createSelector } from "reselect";
import map from "lodash/map";
import getAxios from "services/axios";
import { createCachedFunction } from "services/utils";
import { timeZones } from "services/dateTime";

const axios = getAxios("idV2");

export const getCountries = createCachedFunction(() => axios.get("/api/countries")).cachedFunction;
export const getCountriesOptions = () => getCountries().then( (countriesObj) => {
  return Object.keys(countriesObj).reduce((acc, key) => {
    acc.push({
      value: key,
      label: countriesObj[key],
    });
    return acc;
  }, []);
});

export const getTimezonesOptions = () => timeZones.map((v) => ({
  value: v,
  label: v,
}));

export const lists = (state) => state.entities.lists;
export const glo = createSelector(
  [lists], (lists) => lists.map((list) => ({ value: list.id, label: list.name })),
);


export const tags = (state) => state.entities.tags;
export const gto = createSelector(
  [tags], (tags) => map(tags, (tag) => ({ value: tag.id, label: tag.name })),
);

export const flows = (state) => state.entities.flows;
export const getFlowsOptions = createSelector(
  [flows], (flows) => map(flows, (flow) => ({ value: flow.id, label: flow.name })),
);


export const schedules = (state) => state.entities.schedules;
export const getSchedulesOptions = createSelector(
  [schedules], (schedules) => map(schedules, (schedule) => ({ value: schedule.id, label: schedule.name })),
);
