import type { AvatarProps } from "@gs/uiKit/dataDisplay/Avatar";
import { Avatar } from "@gs/uiKit/dataDisplay/Avatar";
import type { TeamId } from "@gs/core/domain/Team/Team";
import { useTeamColors } from "domain/team/hook/useTeamColors";
import { useFindOrFetchTeam } from "domain/team/hook/useFindOrFetchTeam";

export const TeamAvatarBase = (props: {hex?: string | null; name: string } & AvatarProps) => {
  const {
    hex,
    name,
    ...propsToPropagate
  } = props;
  const { darkColor, lightColor } = useTeamColors(hex || null);

  return (
    <Avatar
      shape="square"
      {...propsToPropagate}
      style={{
        color: darkColor,
        backgroundColor: lightColor,
        ...propsToPropagate.style,
      }}
    >
      { name && String.fromCodePoint(name.codePointAt(0)!) }
    </Avatar>
  );
};

export const TeamAvatar = (props: {teamId: TeamId } & AvatarProps) => {
  const {
    teamId,
    ...propsToPropagate
  } = props;
  const [team] = useFindOrFetchTeam(teamId);

  if (!team) return null;

  return (
    <TeamAvatarBase
      hex={team.color}
      name={team.name}
      {...propsToPropagate}
    />
  );
};
