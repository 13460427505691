import * as actions from "./consts";
import getAxios from "services/axios";
import { createCachedFunction } from "../utils";
import store from "store/store";
import { getServersByUuid } from "../servers/actions";
import { loadMailboxes } from "../mailboxes/actions";

const axios = getAxios("flowV2");
const leadsAxios = getAxios("contactV2");


export const senderProfilesCacher = createCachedFunction((params) => (dispatch) => {
  const ids = [];
  const linkedin_uuids = [];
  return axios.get("/api/sender-profiles", {
    params,
  })
    .then((response) => {
      const { data } = response;
      data.forEach((element) => {
        ids.push(element.uuid);
        if (element.linkedin_account_uuid) {
          linkedin_uuids.push(element.linkedin_account_uuid);
        }
      });
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: data,
      });
      if (ids.length > 0) {
        dispatch(getSenderProfileCounters(ids));
        dispatch(loadMailboxes({
          filter: { sender_profile_uuid: ids },
          limit: ids.length,
        }));
      }
      if (linkedin_uuids.length > 0) {
        dispatch(getServersByUuid(linkedin_uuids)).then((res) => {
          dispatch({
            type: actions.SEED_PROFILES_SERVERS,
            servers: res,
          });
        });
      }
      return response;
    });
}, { recursively: true });

export const loadSenderProfilesList = (params) => (dispatch) => {
  return axios.post("/api/sender-profiles/list", params)
    .then((res) => {
      const { data } = res;
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: data,
      });
      return res;
    });
};

export const senderProfilesMetricsCacher = createCachedFunction((uuids) => (dispatch) => {
  return dispatch(getSenderProfileCounters(uuids));
});

const getProfilesMetrics = senderProfilesMetricsCacher.cachedFunction;

export const getSenderProfileCounters = (uuids, config) => async (dispatch) => {
  const data = await leadsAxios.post("/api/leads/metrics", {
    sender_profiles: uuids,
    metrics: [
      "inbox_count",
      "linkedin_inbox_count",
      "sent_count",
      "linkedin_sent_count",
      "email_sent_count",
      "email_inbox_count",
      "unread_count",
      "unread_count_email",
      "unread_count_linkedin",
    ],
  }, config);
  data.metrics.total = data.total;

  dispatch({
    type: actions.SEED_PROFILE_COUNTERS,
    counters: data.metrics,
  });

  return data.metrics;
};


export const refreshProfilesCounters = (status = false, delay = 7000, showLoading = true) => (dispatch, getState) => {
  const state = getState();
  const uuids = Object.keys(state.entities.senderProfiles);
  if (!uuids.length) return;

  if (status) {
    if (showLoading) {
      dispatch({
        type: actions.SET_PROFILE_COUNTERS_LOADING,
      });
    }
    setTimeout(() => {
      dispatch(getProfilesMetrics(uuids));
    }, delay);
  } else {
    dispatch(getProfilesMetrics(uuids));
  }

};

export const getSenderProfiles = senderProfilesCacher.cachedFunction;

export const getSenderProfile = (senderProfileId) => (dispatch) => {
  return axios.get(`/api/sender-profiles/${senderProfileId}`).then((senderProfile) => {
    dispatch({
      type: actions.SEED_PROFILES,
      profiles: [senderProfile],
    });
    return senderProfile;
  });
};

// export const getSenderProfile = (senderProfileId, ...other) => async (dispatch) => {
//   const senderProfile = await axios.get(`/api/sender-profiles/${senderProfileId}`);

//   dispatch({
//     type: actions.SEED_PROFILES,
//     profiles: [senderProfile],
//   });

//   return senderProfile;
// };

export const getLinkedinLimits = (senderProfileUuid) => axios.get(`api/sender-profile/${senderProfileUuid}/limits/linkedin-list`);

export const getLimitsByTaskType = ({ senderProfileUuid, taskType }) =>
  axios.get(`api/sender-profile/${senderProfileUuid}/limits/by-task-type/${taskType}`);

export const updateLimit = ({ id, params }) => axios.put(`/api/sender-profile/limits/${id}`, params);

export const createSenderProfile = ({ params }) => (dispatch) => {
  return axios.post("api/sender-profiles", params)
    .then((profile) => {
      senderProfilesCacher.clearCache();
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: [profile],
      });
      return profile;
    });
};

export const editSenderProfile = ({ uuid, params }) => (dispatch) => {
  return axios.put(`api/sender-profiles/${uuid}`, params)
    .then((profile) => {
      senderProfilesCacher.clearCache();
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: [profile],
      });
    });
};

export const enableSenderProfile = (uuid) => (dispatch) => {
  return axios.put(`api/sender-profiles/${uuid}/enable`)
    .then((profile) => {
      // senderProfilesCacher.clearCache();
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: [profile],
      });
    });
};

export const disableSenderProfile = (uuid) => (dispatch) => {
  return axios.put(`api/sender-profiles/${uuid}/disable`)
    .then((profile) => {
      // senderProfilesCacher.clearCache();
      dispatch({
        type: actions.SEED_PROFILES,
        profiles: [profile],
      });
    });
};

export const deleteSenderProfile = (uuid) => (dispatch) => {
  return axios.delete(`api/sender-profiles/${uuid}`)
    .then(() => {
      senderProfilesCacher.clearCache();
      dispatch({
        type: actions.DELETE_SENDER_PROFILE,
        profileId: uuid,
      });
    });
};

export const getProfilesOptions = (query) => store.dispatch(getSenderProfiles({ limit: 100 }))
  .then(({ data }) => data.reduce((res, profile) => {
    if (RegExp(query, "i").test(`${profile.first_name} ${profile.last_name}`) || typeof query !== "string") {
      res.push({
        value: profile.uuid,
        label: `${profile.first_name} ${profile.last_name}`,
      });
    }
    return res;
  }, []));

export const getSenderProfilesOptions = (query = "", params = {}) => {
  return Promise.resolve(store.dispatch(getSenderProfiles({ ...params, limit: 300, filter: { q: query, ...params.filter } })))
    .then(({ data }) => data.map((profile) => {
      return { value: profile.uuid, label: `${profile.first_name} ${profile.last_name}` };
    }));
};

export const getSenderProfilesOptionsWithAvatar = (query = "", params = { withAll: false }) => {
  return Promise.resolve(store.dispatch(getSenderProfiles({ limit: 300, filter: { q: query } })))
    .then(({ data }) => {
      const options = data.map((profile) => {
        return { value: profile.uuid, label: `${profile.first_name} ${profile.last_name}`, avatarUrl: profile.avatar_url || "empty" };
      });
      if (params.withAll) {
        options.unshift({ value: [], label: "All Sender Profiles" });
      }
      return options;
    });
};
